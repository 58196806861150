import http from "./httpService";
const apiEndpoint =  "/voice";

export function placeCall(projectId, req) {
  return http.post(apiEndpoint + "/" + "placeoutboundcall" + "/" + projectId, req )}


  export function registerTwilioNumber(projectId, req) {
    return http.post(apiEndpoint + "/" + "registerNumber" + "/" + projectId, req )}  


