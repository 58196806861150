import React, { createContext, useContext, useState, useEffect } from 'react';
import * as profileService from "../services/profileService";
import * as projectService from "../services/projectService";
import { getInitials } from '../utils/nameHelper';

const CompanyProjectsContext = createContext();

export const useCompanyProjects = () => useContext(CompanyProjectsContext);

export const CompanyProjectsProvider = ({ children }) => {
    const [userName, setUserName] = useState(null);
    const [company, setCompany] = useState(null);
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [firstNameShort, setFirstNameShort] = useState(null);


    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const responseData = await profileService.allUserDetails();
            if (responseData.status === 200) {
                const firstName = responseData.data.firstName.split(' ')[0];
                const lastName = responseData.data.lastName.split(' ')[0];
                const initials = getInitials(firstName, lastName);
                setUserName(responseData.data.user);
                setFirstNameShort(initials);
                setCompany(responseData.data.company);
                const response = await projectService.getAllProjects(responseData.data.company._id);
                setProjects(response.data);
            }
        } catch (ex) {
            setError(ex);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [refresh]);

    return (
        <CompanyProjectsContext.Provider value={{ company, projects, loading, error, fetchData, refresh, setRefresh,userName, firstNameShort}}>
            {children}
        </CompanyProjectsContext.Provider>
    );
};