import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as widgetService from "../../services/widgetService";
import * as embedService from "../../services/embedService";
import { message } from "antd";
import { Subheading } from "../../common/heading";
import CopyIcon from "../../images/copy.svg";
import ButtonGroups from "../../common/buttonGroups";
const _ = require("lodash");

function ChatEmbed({
  projects,
  activeProject,
  settings,
  settingReload,
  setSettingReload,
}) {
  const [formFieldforWidget, setFormFieldforWidget] = useState({
    brandColor: "",
    fontColor: "",
    iconColor: "",
    inputfieldFontColor: "",
    firstMessage: "",
    question:""
  });

  const [tabState, setTabState] = useState("Basic");
  const [reload, setReload] = useState(false);

  const [errorMessage] = useState({});

  const buttonLabels = ["Basic", "Brand settings"];

  const handleButtonClick = (event) => {
    const buttonValue = event.target.value;
    setTabState(buttonValue);
  };

  useEffect(() => {
    {
      const fetchData = async () => {
        const response = await widgetService.allWidgetDetails(
          projects[activeProject]._id
        );
        const style = _.pick(response.data.widget, [
          "brandColor",
          "iconColor",
          "inputfieldFontColor",
          "fontColor",
          "firstMessage",
          "question",
          "firstMessage"
          //   "question",
          //   "title",
          //   "bio",
          //   "embedTitle",
          //   "navbarDisplay",
          //   "formImage",
          //   "formTitle",
          //   "formDescription",
          //   "formHeading",
          //   "formBackground",
          //   "formFontColor",
          //   "firstMessage",
          //   "imageWidth",
          //   "multiStateForm",
          //   "initialStateMessage",
          //   "buttonText",
        ]);
        // console.log("response", response)
        setFormFieldforWidget(() => ({ ...style }));
        // let data = {};
        // data.title = style.title;
        // data.question = style.question;
        // setFormField(data);

        // setSideBar(style.navbarDisplay === "block" ? true : false);

        // let data1 = {};
        // //  data1.bio= style.bio;
        // //  data1.embedTitle= style.embedTitle;
        // data1.multiStateForm = style.multiStateForm
        //   ? style.multiStateForm
        //   : false;
        // //  data1.initialStateMessage = style.initialStateMessage ? style.initialStateMessage:""
        // data1.buttonText = style.buttonText ? style.buttonText : "";

        // setFormField3(data1);

        // let data2 = {};
        // data2.formTitle = style.formTitle;
        // data2.formDescription = style.formDescription;
        // data2.formHeading = style.formHeading;
        // data2.formImage = style.formImage;
        // data2.firstMessage = style.firstMessage;
        // data2.imageWidth = style.imageWidth;
        // setInputField5(data2);
      };
      fetchData();
    }
  }, [reload]);

  const [loading, setLoading] = useState(false);

  const [errorMessageForButton] = useState({});

  const templateForButtonStyle = [
    // {
    //     name: "whitelistDomain",
    //     description:"Add the domain where you will host the widget to securely make voice calls on the website",
    //     label: "",
    //     type: "text",
    //     style: "",
    //   },
    {
      name: "brandColor",
      label: "Brand color",
      type: "color",
      style: "",
    },
    {
      name: "fontColor",
      label: "Font color",
      type: "color",
      style: "",
    },
    {
      name: "iconColor",
      label: "Input Field Icon color",
      description: "Specify the color for the icons inside the input field.",
      type: "color",
      style: "",
    },
    {
      name: "inputfieldFontColor",
      description: "Specify the color for the font inside the input field.",
      label: "Input Field Font Color",
      type: "color",
      style: "",
    },
    {
      name: "question",
      description: "This is the message customers see when the chat is in an inactive state.",
      label: "Call to Action",
      type: "textArea",
      style: { height: "100px" },
    },
    {
      name: "firstMessage",
      description: "This is the first message customers see when opening the chat widget.",
      label: "First Message",
      type: "textArea",
      style: { height: "100px" },
    },
  ];

  const buttonInfoForButton = {
    label: "Save configuration",
    style: "entire-width",
  };

  const schemaForButton = {
    // buttonName: Joi.string().allow(null,"").max(5000).label("Button Name"),
    brandColor: Joi.string().max(5000).label("Button Color"),
    fontColor: Joi.string().max(5000).label("Icon Color"),
    iconColor: Joi.string().max(5000).label("Icon Color"),
    inputfieldFontColor: Joi.string().max(5000).label("Icon Color"),
    firstMessage: Joi.string().max(5000).label("First Message"),
    question: Joi.string().max(50).label("CTA"),

    // // whitelistDomain: Joi.string().max(5000).required().label("WhiteList Doamain"),
    // paddingLR: Joi.string().required().label("Padding Left Right"),
    // paddingTB: Joi.string().required().label("Padding Top Bottom"),
    // fontSize: Joi.string().required().label("fontSize"),
    // width: Joi.string().required().label("Width"),
    // interruptionThreshold: Joi.number().min(-50).max(50).required()
  };

  const handleSubmit = async (data) => {
    // inputValue.title = data.title?data.title:inputValue.title;
    // inputValue.question = data.question?data.question:inputValue.question;
    // inputValue.embedTitle = data.embedTitle?data.embedTitle:inputValue.embedTitle
    // inputValue.bio = data.bio? data.bio:inputValue.bio
    // inputValue.multiStateForm = data.multiStateForm? data.multiStateForm:inputValue.multiStateForm
    // inputValue.initialStateMessage = data.initialStateMessage || data.initialStateMessage===""? data.initialStateMessage:inputValue.initialStateMessage
    // inputValue.buttonText = data.buttonText || data.buttonText===""? data.buttonText:inputValue.buttonText;
    // inputValue.navbarDisplay = sideBar===true?"block":"none"
    // inputValue.formTitle = data.formTitle?data.formTitle:inputValue.formTitle;
    // inputValue.formDescription = data.formDescription?data.formDescription:inputValue.formDescription;
    // inputValue.formHeading = data.formHeading?data.formHeading:inputValue.formHeading;
    // inputValue.formImage = data.formImage?data.formImage:inputValue.formImage;
    // inputValue.firstMessage = data.firstMessage || data.firstMessage===""? data.firstMessage:inputValue.firstMessage;
    // inputValue.imageWidth = data.imageWidth? data.imageWidth:inputValue.imageWidth;

    try {
      const response = await widgetService.updateWidget(
        projects[activeProject]._id,
        data
      );
      if (response.status === 200) {
        message.success("Configuration saved");
        setLoading(false);
        setReload(!reload);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response.data.msg);
        return;
      }
    }
  };

  let projectId = projects[activeProject]._id;

  let chatWidgetCode = `
  <script>
  (function (w, d, s, o, f, js, fjs) {
   w[o] =
   w[o] ||
   function () {
       (w[o].q = w[o].q || []).push(arguments);
   };
  (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
  js.id = o;
  js.src = f;
  js.async = 1;
  fjs.parentNode.insertBefore(js, fjs);
  })(window, document, "script", "_hw", "https://superdashhq.com/widget.js");
  _hw("init", { debug: true, projectID: "${projectId}"  });
  </script>         
  `;

  let fullPageEmbed = `
  <iframe id="super_embed" style="height:100vh; width:100vw" src="https://www.superdashhq.com/embed?projectID=${projectId}"></iframe>         
  `;

  const handleCopy = (item) => {
    navigator.clipboard
      .writeText(item)
      .then(() => {
        message.success("Copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy the embed code");
        console.error("Failed to copy: ", err);
      });
  };

  const handleWhiteLabel = async (passData) => {
    let newURL = passData.formFieldforWhitelabel.replace(/\/$/, "");

    let data = {};
    data.projectId = projectId;
    data.domains = [];
    data.domains.push(newURL);

    try {
      const response = await embedService.whitelistDomain(data);

      if (response.status === 200) {
        message.success("New domain whitelisted.");
        setLoading(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
        setLoading(false);
        return;
      }
    }
  };


  const [formFieldforWhitelabel, setFormFieldForWhiteLabel] = useState("")

  const [errorMessageForWhiteLabel] = useState({});


  const templateForWhiteLabel = [

    {
      name: "formFieldforWhitelabel",
      label: "Whitelist domain",
      type: "text",
      style: "",
    }
  ];


  const schemaForWhitelabel = {
    formFieldforWhitelabel: Joi.string().max(5000).label("Button Color"),
  };

  const buttonInfoForWhitelabel = {
    label: "Whitelist Domain",
    style: "entire-width",
  };

  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
            <div className="flex justify-end mb-5">
              <ButtonGroups
                handleButtonClick={handleButtonClick}
                buttons={buttonLabels}
                activeLabel={tabState}
              />
            </div>
            {tabState === "Basic" && (
              <div>
                   <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
                <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                  <span>Whitelist your domain</span>
                </div>
                <div className="px-30 pb-16 p-10">
                  <Form
                    key={JSON.stringify(formFieldforWhitelabel)}
                    errorMessage={errorMessageForWhiteLabel}
                    inputField={formFieldforWhitelabel}
                    template={templateForWhiteLabel}
                    schema={schemaForWhitelabel}
                    buttonInfo={buttonInfoForWhitelabel}
                    onSubmit={handleWhiteLabel}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </div>
                </div>
                <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-5">
                  <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                    <span>Chat Widget</span>
                    <div
                      onClick={() => handleCopy(chatWidgetCode)}
                      className="flex items-center cursor-pointer"
                    >
                      <img
                        src={CopyIcon}
                        alt="Copy Icon"
                        className="w-6 h-6 mr-2"
                      />
                      <Subheading>Copy chat widget code</Subheading>
                    </div>
                  </div>
                </div>

                <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-5">
                  <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                    <span>Full Page Embed</span>
                    <div
                      onClick={() => handleCopy(fullPageEmbed)}
                      className="flex items-center cursor-pointer"
                    >
                      <img
                        src={CopyIcon}
                        alt="Copy Icon"
                        className="w-6 h-6 mr-2"
                      />
                      <Subheading>Copy full page embed code</Subheading>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {tabState === "Brand settings" && (
              <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
                <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                  <span>Configure your settings</span>
                </div>

                <div className="px-30 pb-16 p-10">
                  <Form
                    key={JSON.stringify(formFieldforWidget)}
                    errorMessage={errorMessageForButton}
                    inputField={formFieldforWidget}
                    template={templateForButtonStyle}
                    schema={schemaForButton}
                    buttonInfo={buttonInfoForButton}
                    onSubmit={handleSubmit}
                    loading={loading}
                    setLoading={setLoading}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default ChatEmbed;
