import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../common/table";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../common/alert";
import { Heading } from "../common/heading";
import { Button } from "../common/button";
import * as campaignService from "../services/campaignService";
import { Badge } from "../common/badge";
import Pagination from "../components/Pagination";
import Joi from "joi-browser";
import Form from "../components/Form";
import { message } from "antd";
import Configuration from "./HomeCampaign/Configuration";
import {
  Cog6ToothIcon,
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import {
  Dropdown,
  DropdownButton,
  DropdownDescription,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../common/dropdown";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import BulkUpload from "./HomeCampaign/BulkUpload";

function HomeCampaigns({ projects, activeProject }) {
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Define how many items per page
  let [isOpen, setIsOpen] = useState(false);
  let [formState, setFormState] = useState("table");
  const [pageLoading, setPageLoading] = useState(true);

  let [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (projects && activeProject !== undefined && projects[activeProject]) {
        const { data } = await campaignService.getAllCalls(
          projects[activeProject]._id
        );

        const reversedData = data.reverse();
        setCampaignData(reversedData);
        setPageLoading(false);

        // setKeys(data);
      }
    };

    fetchData();
  }, [activeProject, reload, projects]);

  const [inputField] = useState({
    toNumber: "",
    fromNumber: "",
    triggerType: "",
    campaignType: "",
    message: "",
  });

  const [errorMessage] = useState({});

  const template = [
    {
      name: "campaignType",
      label: "Campaign Type",
      type: "select",
      options: [
        {
          id: 0,
          value: "",
          label: "No selection",
        },

        {
          id: 1,
          value: "CALL",
          label: "CALL",
        },
        {
          id: 2,
          value: "SMS",
          label: "SMS",
        },
      ],
    },
    {
      name: "toNumber",
      label: "To Number",
      description: "Please pre-fix country code to the number",
      type: "text",
    },

    {
      name: "fromNumber",
      label: "From Number",
      description: "Leave blank to use default number",
      type: "text",
    },

    {
      name: "triggerType",
      label: "Trigger type",
      type: "select",
      options: [
        {
          id: 0,
          value: "",
          label: "No selection",
        },
        {
          id: 1,
          value: "triggerImmediately",
          label: "Trigger Immediately",
        },
        {
          id: 2,
          value: "triggerBasedOnCampaign",
          label: "Trigger based on campaign configuration",
        },
      ],
    },
    {
      name: "message",
      label: "Message",
      description: "Important for SMS campaigns only",
      type: "textArea",
      style: { height: "100px" },
    },
  ];

  const schema = {
    message: Joi.string().allow(null, "").max(160).label("message"),
    toNumber: Joi.string()
      .regex(/^\+?[0-9]+$/)
      .required()
      .label("To Number"),
    fromNumber: Joi.string()
      .regex(/^\+?[0-9]+$/)
      .allow(null, "")
      .label("From Number"),
    triggerType: Joi.string().required().label("Trigger Type"),
    campaignType: Joi.string().required().label("Campaign Type"),
  };

  const handleCreate = async (data) => {
    setLoading(true);

    try {
      const response = await campaignService.addToCampaign(
        projects[activeProject]._id,
        data
        // data.eventName,
        // data.endpointUrl,
        // data.authRequired,
        // data.authKey
      );
      if (response.status === 201 || response.status === 200) {
        message.success("Success");
        setLoading(false);
        setIsOpen(false);
        setReload(!reload);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsOpen(false);
      message.error(error.message);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(campaignData.length / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  const paginatedData = campaignData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  function formatDuration(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  }

  const formatFollowUpTime = (timestamp) => {
    return moment(timestamp).local().format("MMMM Do, h:mm:ss a");
  };

  if (formState === "table")
    return (
      <div>
        <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10 pt-4 pr-3 pl-3">
          <Heading>Campaigns logs</Heading>
          <div className="flex gap-4">
            <Button
              outline
              onClick={() => setFormState("configuration")}
              type="button"
              className="flex items-center space-x-2"
            >
              <Cog6ToothIcon className="h-5 w-5" />
              <span>Configuration</span>
            </Button>
            {/* <Button onClick={() => setIsOpen(true)} type="button">
              Add to campaign
            </Button> */}

            <Dropdown >
              <DropdownButton color="dark">
                Add
                <ChevronDownIcon />
              </DropdownButton>
              <DropdownMenu>
                <DropdownItem onClick={() => setIsOpen(true)}>
                  <DropdownLabel>Single Contact</DropdownLabel>
                  <DropdownDescription>Add a contact</DropdownDescription>
                </DropdownItem>
                <DropdownItem onClick={() => setIsBulkUploadOpen(true)}>
                  <DropdownLabel >
                    Bulk upload
                  </DropdownLabel>
                  <DropdownDescription>
                    Import Contacts in Bulk
                  </DropdownDescription>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>

        {campaignData && campaignData.length === 0 && !pageLoading && (
          <button
            onClick={() => setIsOpen(true)}
            type="button"
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none"
          >
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
              />
            </svg>
            <span className="mt-2 block text-sm font-semibold text-gray-900">
              Your campaigns table is empty
            </span>
            <p className="mt-5">
              The campaign is the best way to send an outbound call or SMS.{" "}
              <br />
              It always comes with many features, such as the ability to
              schedule your campaign, <br /> follow up, configure timings for
              follow-ups, and more.
            </p>
          </button>
        )}

        {pageLoading && (
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-1 animate-pulse">
            <TableHead>
              <TableRow>
                <TableHeader>UserIdentifier</TableHeader>
                <TableHeader>Type</TableHeader>
                {/* <TableHeader>Touch Points</TableHeader>
                <TableHeader>Engagements</TableHeader> */}
                <TableHeader>Next Follow-Up Time</TableHeader>
                <TableHeader>Last Call Status</TableHeader>
              </TableRow>
            </TableHead>

            <TableBody>
              {Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <div className="h-6 bg-gray-100 rounded"></div>
                  </TableCell>
                  <TableCell>
                    <div className="h-6 bg-gray-100 rounded"></div>
                  </TableCell>
                  {/* <TableCell>
                    <div className="flex justify-between items-center space-x-2">
                      <div className="h-6 bg-gray-100 w-full rounded"></div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="h-6 bg-gray-100 rounded"></div>
                  </TableCell> */}
                  <TableCell>
                    <div className="h-6 bg-gray-100 rounded"></div>
                  </TableCell>
                  <TableCell>
                    <div className="h-6 bg-gray-100 rounded"></div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {campaignData && campaignData.length > 0 && (
          <>
            <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-1">
              <TableHead>
                <TableRow>
                  <TableHeader>UserIdentifier</TableHeader>
                  <TableHeader>Type</TableHeader>
                  {/* <TableHeader>Touch Points</TableHeader>
                  <TableHeader>Engagements</TableHeader> */}
                  <TableHeader>Next Follow-Up Time</TableHeader>
                  <TableHeader>Last Call Status</TableHeader>
                </TableRow>
              </TableHead>

              <TableBody>
                {paginatedData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="font-medium">
                      {item.userIdentifier}
                    </TableCell>
                    <TableCell className="font-medium">
                      {item.sourceOfConversation}
                    </TableCell>
                    {/* <TableCell className="flex justify-between items-center">
                      <div className="flex items-center space-x-2">
                        <div className="flex items-center border p-1 bg-gray-100 text-gray-800 rounded-md">
                          <div className="bg-gray-300 p-1 rounded-full">
                            <PhoneArrowDownLeftIcon className="h-4 w-4 text-gray-800" />
                          </div>
                          <span className="ml-2 bg-white text-gray-800 p-1 rounded-md">
                            {item.inboundEngagement || 0}
                          </span>
                        </div>
                        <div className="flex items-center border p-1 bg-gray-100 text-gray-800 rounded-md">
                          <div className="bg-gray-300 p-1 rounded-full">
                            <PhoneArrowUpRightIcon className="h-4 w-4 text-gray-800" />
                          </div>
                          <span className="ml-2 bg-white text-gray-800 p-1 rounded-md">
                            {item.outboundEngagement || 0}
                          </span>
                        </div>
                      </div>
                    </TableCell> */}

                    {/* <TableCell>
                      {item.conversation ? item.conversation.length : 0}
                    </TableCell> */}
                    <TableCell>
                      <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                        {item.userResponded === true
                          ? "User responded"
                          : item.nextFollowUpTime === 0
                          ? "No follow-up scheduled"
                          : formatFollowUpTime(item.nextFollowUpTime)}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                        <Badge
                          color={
                            item.callStatus === "Scheduled" ||
                            item.callStatus === "Ongoing"
                              ? "yellow"
                              : item.callStatus === "Completed"
                              ? "emerald"
                              : "rose"
                          }
                        >
                          {item.callStatus}
                        </Badge>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Pagination
              currentPage={currentPage}
              totalItems={campaignData.length}
              itemsPerPage={itemsPerPage}
              onPageChange={onPageChange}
            />
          </>
        )}
        <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
          <AlertTitle>Add to campaign</AlertTitle>
          {/* <AlertDescription>
                    For security purposes, it's advised that you save this secret
                    key in a secure and accessible location. Once saved, please
                    note that it won't be visible again through your Superdash
                    account. If the secret key is lost, generating a new one will
                    be required.
                  </AlertDescription> */}
          <AlertBody style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              {" "}
              <Form
                key={JSON.stringify(inputField)}
                errorMessage={errorMessage}
                inputField={inputField}
                template={template}
                schema={schema}
                buttonInfo={{
                  style: "",
                  buttons: "2",
                  primaryLabel: "Add",
                  secondaryLabel: "Close",
                }}
                onSubmit={handleCreate}
                loading={loading}
                setLoading={setLoading}
                handleSecondary={handleClose}
              />
            </div>
          </AlertBody>
        </Alert>

        <BulkUpload  projects={projects}  activeProject={activeProject} isBulkUploadOpen={isBulkUploadOpen} setIsBulkUploadOpen={setIsBulkUploadOpen} />
      </div>
    );

  if (formState === "configuration")
    return (
      <Configuration
        projects={projects}
        activeProject={activeProject}
        setFormState={setFormState}
      />
    );
}

export default HomeCampaigns;

