import React, { useState, useEffect, useRef } from "react";
import * as conversationService from "../services/conversationService";
import {
  PhoneArrowDownLeftIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/solid";
import { message } from "antd";
import * as webhookstatusService from "../services/webhookstatusService";
import * as voiceService from "../services/voiceService";
import { Button } from "../../src/common/button";
import lock from "../../src/images/lock.svg";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../common/alert";

function HomeThread({ projects, activeProject }) {
  const [conversations, setConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [chats, setChats] = useState([]);
  const [activeConversation, setActiveConversation] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [selection, setSelection] = useState(0);
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const [callRecordingUrl, setCallRecordingURL] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [isLoadingRecording, setIsLoadingRecording] = useState(false);


  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const fetchData = async () => {
      if (projects[activeProject]) {
        const convo = await conversationService.allConversation(
          projects[activeProject]._id
        );
        convo.data.conversations.reverse();
        setConversations(convo.data);

        // console.log(convo.data)

        setActiveConversation(
          convo.data.conversations && convo.data.conversations[selection]
            ? convo.data.conversations[selection].messages
            : []
        );
        setSelectedItem(convo.data.conversations[selection]);
        setLoadingState(
          convo.data.conversations && convo.data.conversations[selection]
            ? false
            : true
        );
        // const response = await projectService.getSettings(
        // projects[activeProject]._id
        // );
        // setSettings(response.data);
      }
    };
    fetchData();
  }, [
    activeProject,
    projects,
    // conversations,
    refresh,
  ]);

  useEffect(() => {
    setCallRecordingURL("");
  }, [selectedItem]);

  useEffect(() => {
    if (conversations && conversations.conversations) {
      if (conversations.conversations.length >= 1) {
        setChats(conversations.conversations[0].messages);
        setSelectedItem(conversations.conversations[0]);
      }
    }
  }, []);

  useEffect(() => {
    if (searchItem) {
      const filtered = conversations.conversations.filter((convo) =>
        convo.callId.includes(searchItem)
      );
      setFilteredConversations(filtered);
    } else {
      setFilteredConversations(conversations.conversations);
    }
  }, [searchItem, conversations]);

  useEffect(() => {
    return () => {
      if (callRecordingUrl) {
        URL.revokeObjectURL(callRecordingUrl);
      }
    };
  }, [callRecordingUrl]);

  const handleSelection = (item, index) => {
    setChats(item.messages);
    setSelection(index);
    setSelectedItem(item);
    setAttachments([]);
    setActiveConversation(item.messages);
  };

  const handleRetry = async () => {
    try {
      const response = await webhookstatusService.manualRetry(
        selectedItem.project,
        selectedItem._id
      );
      if (response.status === 200) {
        message.success(
          "Extraction complete. Your extraction status will be updated soon."
        );
        setRefresh(!refresh);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
        return;
      }
    }
  };

  const handleGetRecordings = async () => {
    try {
      setIsLoadingRecording(true);
      const response = await voiceService.getRecordingLink(
        selectedItem.project,
        selectedItem._id
      );
      const blob = response.data;
      const audioUrl = URL.createObjectURL(blob);
      setCallRecordingURL(audioUrl);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        return;
      }
    } finally {
      setIsLoadingRecording(false);
    }
  };


  const handleDelete = async () => {
    try {
      const response = await conversationService.deleteConversation(
        selectedItem.project,
        selectedItem._id
      );
      if (response.status === 200) {
        message.success("Conversation deleted succesfully");
        setSelection(0);
        setShowOptions(false);
        setRefresh(!refresh);
        setIsOpen(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data.msg);
        return;
      }
    }
  };

  const handleSearch = (event) => {
    setSearchItem(event.target.value);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    }).format(date);
  };

  console.log("filteredConversations", filteredConversations);

  if (loadingState === false)
    return (
      <div className="flex h-screen bg-slate-50 overflow-hidden">
        {/* Left Side: Chat List (always visible) */}
        <div className="w-1/4 bg-white border-r border-gray-200 flex flex-col">
          <div className="flex items-center justify-between p-6 border-b border-gray-200 sticky top-0 bg-white">
            <h2 className="text-xl font-semibold text-gray-800">
              Active Conversations
            </h2>
          </div>
          <div className="p-4 sticky top-[4.5rem] bg-white">
            <input
              type="search"
              onChange={handleSearch}
              placeholder="Search by Call ID"
              className="w-full rounded border border-gray-300 px-4 py-2 text-sm text-gray-600 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="overflow-y-auto flex-grow">
            {filteredConversations &&
              filteredConversations.map((item, index) => (
                <div
                  onClick={() => handleSelection(item, index)}
                  key={item.id}
                  className={`flex items-center p-2 transition duration-150 ease-in-out cursor-pointer ${index === selection ? "bg-gray-200" : "hover:bg-gray-100"
                    }`}
                >
                  <div className="h-10 w-10 rounded-full bg-slate-100 flex justify-center items-center m-1">
                    <PhoneArrowDownLeftIcon
                      className="h-6 w-6 text-gray-700"
                      aria-label={item.name}
                    />
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium text-gray-700">
                      {item.sourceOfConversation}
                    </p>
                    <p className="text-sm text-gray-500">
                      {item.userIdentifier}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* Right Side: Chat Window (adjustable width) */}
        <div className="flex-grow bg-white border-r border-gray-200 flex flex-col">
          <div className="flex justify-between items-center p-6 border-b border-gray-200 sticky top-0 bg-white">
            <h2 className="text-xl font-semibold text-gray-800">Messages</h2>
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={(e) => {
                  setShowOptions((prev) => !prev);
                }}
                className="p-2"
              >
                <EllipsisHorizontalIcon className="h-5 w-5 text-gray-500" />
              </button>
              {showOptions && (
                <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-2xl z-20">
                  <a
                    onClick={() => setIsOpen(true)}
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Delete Conversation
                  </a>
                  <a
                    onClick={handleRetry}
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Retry extraction
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="overflow-y-auto flex-grow p-6 space-y-4">
            {activeConversation &&
              activeConversation.map((item, index) => (
                <React.Fragment key={index}>
                  {item.q.trim() !== "" && (
                    <div className="flex justify-start">
                      <div className="flex items-end">
                        <div className="max-w-sm md:max-w-md rounded-lg bg-blue-100 p-4">
                          <p className="text-sm text-gray-700 break-words">
                            {item.q}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {item.a.trim() !== "" && (
                    <div className="flex justify-end">
                      <div className="flex items-end">
                        <div className="max-w-sm md:max-w-md rounded-lg bg-blue-600 p-4 text-white">
                          <p className="text-sm break-words">{item.a}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>
        </div>

        {/* Aside Component (fixed width) */}
        <aside className="w-96">
          <div className="p-4">
            {isLoadingRecording ? (
              <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center">
                <div>
                  <div className="text-xl font-medium text-black">
                    Downloading audio...
                  </div>
                </div>
              </div>
            ) : callRecordingUrl !== "" ? (
              <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center">
                <div>
                  <div className="text-xl font-medium text-black">
                    Call Recording
                  </div>
                  <audio controls className="mt-2">
                    <source src={callRecordingUrl} type="audio/wav" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </div>
            ) : (
              (selectedItem.sourceOfConversation === "INBOUNDVOICE" || selectedItem.sourceOfConversation === "OUTBOUNDVOICE") && (
                <div
                  onClick={handleGetRecordings}
                  className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center cursor-pointer"
                >
                  <div className="flex flex-row items-center">
                    <img
                      style={{ width: "30px" }}
                      className="m-2"
                      src={lock}
                      alt="Lock Icon"
                    />
                    <span className="bg-white pr-2 text-sm text-gray-500">
                      Download call recording securely
                    </span>
                  </div>
                </div>
              )
            )}

            <div className="bg-white rounded-xl max-w-sm mx-auto p-6 shadow-md space-x-4 mt-6">
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-start">
                  <span className="bg-white pr-2 text-sm text-gray-500">
                    Call Details
                  </span>
                </div>
              </div>
              <div className="mt-4 flex justify-between">
                <span className="text-gray-500">Call ID</span>
                <span className="text-xs text-gray-400">
                  {selectedItem && selectedItem.callId}
                </span>
              </div>
              <div className="mt-4 flex justify-between">
                <span className="text-gray-500">Call duration</span>
                <span className="text-gray-400">
                  {selectedItem &&
                    selectedItem.attributes &&
                    selectedItem.attributes.callDuration}
                </span>
              </div>
              <div className="mt-4 flex justify-between">
                <span className="text-gray-500">Call from</span>
                <span className="text-gray-400">
                  {selectedItem &&
                    selectedItem.attributes &&
                    selectedItem.attributes.phone}
                </span>
              </div>
              <div className="mt-4 flex justify-between">
                <span className="text-gray-500">Call type</span>
                <span className="text-gray-400">
                  {selectedItem &&
                    selectedItem.attributes &&
                    selectedItem.attributes.type === "twilioCall"
                    ? "Programmatic Call"
                    : "WebCall"}
                </span>
              </div>
              <div className="mt-4 flex justify-between">
                <span className="text-gray-500">Conversation start time</span>
                <span className="text-gray-400">
                  {selectedItem && selectedItem.createdAt
                    ? formatDate(selectedItem.createdAt)
                    : ""}
                </span>
              </div>
            </div>
            <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
              <AlertTitle>Delete Conversation</AlertTitle>
              <AlertDescription>
                Once deleted, this conversation and the call recordings will be
                completely removed from our system.
              </AlertDescription>
              <AlertBody
                style={{ display: "flex", alignItems: "center" }}
              ></AlertBody>
              <AlertActions>
                <Button plain onClick={() => setIsOpen(false)}>
                  Cancel
                </Button>
                <Button onClick={handleDelete}>OK</Button>
              </AlertActions>
            </Alert>
          </div>
        </aside>
      </div>
    );
  else
    return (
      <div className="flex h-screen bg-slate-50">
        {/* Flex container for the entire view */}
        <main className="flex-grow flex flex-col m-5">
          <div className="relative divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-2 animate-pulse">
            <div className="px-4 py-5 sm:px-6  bg-white rounded">Messages</div>
            <div className="px-4 py-5 sm:p-6 bg-white rounded">
              {/* Placeholder for the form component */}
              <div className="space-y-4">
                <h3>You might not have any conversations yet.</h3>
                <div className="h-10  bg-gray-100 rounded"></div>{" "}
                {/* Placeholder for an input field */}
                <div className="h-10  bg-gray-100 rounded"></div>{" "}
                {/* Another placeholder for an input field */}
                <div className="h-10  bg-gray-100 rounded"></div>{" "}
                {/* Another placeholder for an input field */}
              </div>
            </div>
          </div>
        </main>
        <aside className="w-96 overflow-y-auto border-l border-gray-200 lg:block hidden pb-72">
          <div className="bg-white rounded-2xl mt-7 mx-5 p-8 shadow animate-pulse">
            <div className="relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-start">
                <span className="bg-gray-200 pr-2 text-sm text-gray-500 rounded">
                  Voice recordings
                </span>
              </div>
            </div>
            <div className="space-y-4 mt-4">
              <div className="h-6  bg-gray-100 rounded"></div>{" "}
              {/* Placeholder for a setting option */}
              <div className="h-6  bg-gray-100 rounded"></div>{" "}
              {/* Another placeholder for a setting option */}
              <div className="h-6  bg-gray-100 rounded"></div>{" "}
              {/* Yet another placeholder for a setting option */}
            </div>
          </div>
        </aside>
      </div>
    );
}

export default HomeThread;
