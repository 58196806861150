import http from "./httpService";
const apiEndpoint = "/customerplan";


export function allPlans(companyId) {
    return http.get(apiEndpoint + "/" + companyId);
}


export function addCustomerPlan(companyId, data) {
    const requestData = {
        plans: data
    };
    return http.post(apiEndpoint + "/" + companyId, requestData);
}

export function deletePlan(companyId, planId) {
    return http.delete(apiEndpoint +  "/deleteplan/" + companyId + "/" + planId);
  }

  export function updatePlan(companyId, planId, req) {
    console.log(companyId,planId)
    return http.put(apiEndpoint + "/" + companyId + "/" + planId, req);
  }




