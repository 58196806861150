import React, { useState, useEffect } from "react";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../../common/alert";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as campaignService from "../../services/campaignService";
import { message } from "antd";

function BulkUpload({
  isBulkUploadOpen,
  setIsBulkUploadOpen,
  projects,
  activeProject,
}) {
  const [inputField] = useState({
    sheetURL: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  const template = [
    {
      name: "sheetURL",
      label: "Copy paste your Google sheet link",
      description: `Please add superdash@superdash-382709.iam.gserviceaccount.com as a 'viewer' to this Google Sheet.`,
      type: "text",
    },
  ];

  const schema = {
    sheetURL: Joi.string()
      .uri()
      .required()
      .label("sheetURL"),
  };

  const handleCreate = async (data) => {
    setLoading(true);

    try {
      const response = await campaignService.bulkUpload(
        projects[activeProject]._id,
        data
      );
      if (response.status === 201 || response.status === 200) {
        message.success("Success");
        setLoading(false);
        setIsBulkUploadOpen(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsBulkUploadOpen(false);
      message.error(error.message);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsBulkUploadOpen(false);
  };



  return (
    <div>
      <Alert
        open={isBulkUploadOpen}
        onClose={() => setIsBulkUploadOpen(false)}
        size="sm"
      >
        <AlertTitle>Bulk Upload</AlertTitle>
        {/* <AlertDescription>
                    For security purposes, it's advised that you save this secret
                    key in a secure and accessible location. Once saved, please
                    note that it won't be visible again through your Superdash
                    account. If the secret key is lost, generating a new one will
                    be required.
                  </AlertDescription> */}
        <AlertBody style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            {" "}
            <Form
              key={JSON.stringify(inputField)}
              errorMessage={errorMessage}
              inputField={inputField}
              template={template}
              schema={schema}
              buttonInfo={{
                style: "",
                buttons: "2",
                primaryLabel: "Add",
                secondaryLabel: "Close",
              }}
              onSubmit={handleCreate}
              loading={loading}
              setLoading={setLoading}
              handleSecondary={handleClose}
            />
          </div>
        </AlertBody>
      </Alert>
    </div>
  );
}

export default BulkUpload;
