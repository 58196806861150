import React, { useState, useEffect } from "react";
import { Button } from "../../common/button";
import zohoCRM from "../../images/zohoCRM.svg";
import GHL from "../../images/GHL.png";
import Joi from "joi-browser";
import Form from "../../components/Form";
import * as messengerService from "../../services/messengerService";
import { message } from "antd";
import CopyInput from "../../common/CopyInput";

function Messenger({ activeProject, projects }) {
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (projects[activeProject]._id) {
  //         const response = await thirdPartyServices.getthirdpartyData(
  //           projects[activeProject]._id
  //         );
  //         const { twilioAccountSID, twilioAuthToken, twilioPhoneNumber } = response.data.telephonySettings.twilioSettings;
  //         const twilioSettings = { twilioAccountSID, twilioAuthToken, twilioPhoneNumber };
  //         setFormFieldForTwilio(twilioSettings);

  //       }
  //     } catch (error) {}
  //   };
  //   fetchData();
  // }, [projects, activeProject]);

  const [inputFieldForMessenger] = useState({
    messengerToken:"",
    pageID:""
     });
   
  
  
   
   const [errorMessageForMessager] = useState({});
  
  
    const templateMessenger = [
      {
        name: "messengerToken",
        label: "Messenger Token",
        type: "text",
        style: "",
      },
      {
        name: "pageID",
        label: "pageID",
        type: "text",
        style: "",
  
      },
      
    ];
  
    const buttonInfoforMessenger = { label: "Connect Messenger", style: "entire-width" };
  
    const schemaforMessener = {
      messengerToken: Joi.string().max(300).required().label("Messenger Token"),
      pageID: Joi.string().max(50).required().label("pageID"),
    };
    
  
  
    const handleMessenger = async (data) => {
  
    
      const updatedObj = {};
    
      for (let key in data) {
        if (typeof data[key] === "string") {
          updatedObj[key] = data[key].trim();
        } else {
          updatedObj[key] = data[key];
        }
      }
  
    
      try {
        const response = await messengerService.manageMessenger(projects[activeProject]._id, updatedObj);
    
        if (response.status === 200) {
          message.success("Messenger configuration saved");
          setLoading(false);
        }
      } catch (ex) {
        if (ex.response && ex.response.status >= 400 && ex.response.status < 500) {
          setLoading(false);
          message.error(ex.response.data.msg);
          return;
        }
      }
    };

  // const handleTwilioConnection = async (data) => {
  //   let telephonySettings = {
  //     telephonySettings: {
  //       telephonyProvider: "twilio",
  //       twilioSettings: {
  //         twilioAccountSID: data.twilioAccountSID,
  //         twilioAuthToken: data.twilioAuthToken,
  //         twilioPhoneNumber: data.twilioPhoneNumber,
  //       },
  //     },
  //   };

  //   try {
  //     const response = await thirdPartyServices.thirdpartyUpdate(
  //       telephonySettings,
  //       projects[activeProject]._id
  //     );
  //     if (response.status === 200) {
  //       message.success("Twilio connected");
  //       setLoading(false);
  //     }
  //   } catch (ex) {
  //     if (
  //       ex.response &&
  //       ex.response.status >= 400 &&
  //       ex.response.status < 500
  //     ) {
  //       setLoading(false); // Ensure setLoading is defined and used correctly
  //       message.error(ex.response.data.msg);
  //       setLoading(false);
  //     }
  //   }
  // };


  return (
    <div>
     <div class="flex flex-col items-center bg-white mt-10 ml-[200px]">
      <div className="w-full max-w-md px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-white shadow rounded-2xl">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Connect Messenger
        </h3>
        <div className="mt-2 text-sm text-gray-500">
          <p>
            The easiest way to connect a custom LLM to your Messenger.
          </p>
        </div>
  
        <div>
          <div className="my-5">
            <CopyInput
              value="https://api.superdashhq.com/api/messenger/webhook"
              title="Messenger Webhook URL"
            />
          </div>
  
          <div className="my-5">
            <CopyInput
              value="4915359cede4b64cb190f02bc1b396af"
              title="Verify token"
            />
          </div>

  
          <Form
            key={JSON.stringify(inputFieldForMessenger)}
            errorMessage={errorMessageForMessager}
            inputField={inputFieldForMessenger}
            template={templateMessenger}
            schema={schemaforMessener}
            buttonInfo={buttonInfoforMessenger}
            onSubmit={handleMessenger}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
  
        {/* New instructions at the bottom */}
        <div className="mt-6 text-sm text-gray-500">
          <p>
            Please find instructions to integrate Messenger{" "}
            <a
              href="https://superdashhq.notion.site/Super_-WhatsApp-Integration-Instructions-17933f1b92d44e5588ad510dc2885995"
              className="text-blue-500 underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>.
          </p>
        </div>
      </div>
    </div>
  </div>
  
  );
}

export default Messenger;
