import React,{useState} from "react";

import { message } from "antd";
import Joi from "joi-browser";
import Form from "../components/Form";
import * as authService from "../services/authService";


function ForgotPassword(props) {
  const [inputField] = useState({       
    email: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  // let auth = useAuth();


  const template = [
    {
      name: "email",
      label: "Enter your email here",
      type: "email",
    },
  ];

  const buttonInfo = {
    label: "Reset password",
    style: "entire-width",
    class:
      "w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none",
  };

  const schema = {
    email: Joi.string().email().required().label("email"),
  };

  const handleSubmit = async (email) => {
    try {
      const response = await authService.sendOptOnEmail(email);
      if (response.status === 200) {
        setLoading(false);
        message.success("Please check your email for the reset password link.");
   
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status === 400 &&
        ex.response.status < 500
      ) {
        setLoading(false);
        message.error(ex.response.data.msg);
        return;
      }
    }
  };

  return <div>
        <div>
      <div className="bg-slate-50 flex items-center justify-center h-screen w-screen">
        <div className="relative w-[500px] rounded-xl bg-white shadow-[0px_0px_0px_1px_rgba(9,9,11,0.07),0px_2px_2px_0px_rgba(9,9,11,0.05)] dark:bg-zinc-900 dark:shadow-[0px_0px_0px_1px_rgba(255,255,255,0.1)] p-20">
          <div className="space-y-8">
            <Form
              formTitle={"Reset Password"}
              errorMessage={errorMessage}
              inputField={inputField}
              template={template}
              schema={schema}
              buttonInfo={buttonInfo}
              onSubmit={handleSubmit}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  </div>;
}

export default ForgotPassword;
