import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import lottie from "lottie-web";
import success from "../animation/teaminvite.json";
import { Button } from "../common/button";

function TeamInvite(props) {
  const container = useRef(null);
  const [formState, setFormState] = useState("accept");
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const otp = queryParams.get("OTP");
    const email = queryParams.get("email");

    if (token && otp && email) {
      localStorage.setItem("token", token);
      navigate(`/reset-password?otp=${otp}&email=${email}`);
    } else {
      setError(true);
    }

    let anim;
    if (container.current) {
      anim = lottie.loadAnimation({
        container: container.current, 
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: success, 
      });
    }

    return () => {
      if (anim) {
        anim.destroy();
      }
    };
  }, [location, navigate]);

  if (formState === "accept") {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <div ref={container} className="mb-4"></div>
        {error ? (
          <p className="text-lg text-red-700">Request failed</p>
        ) : (
          <p className="text-lg text-gray-700">Please wait while we process your request</p>
        )}
      </div>
    );
  }

  return null;
}

export default TeamInvite;