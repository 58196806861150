import { Avatar } from "../common/avatar";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../common/dropdown";
import {
  Navbar,
  NavbarDivider,
  NavbarItem,
  NavbarLabel,
  NavbarSection,
  NavbarSpacer,
} from "../common/navbar";
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from "../common/sidebarCatalyst";
import { StackedLayout } from "../common/stacked-layout";
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  Cog8ToothIcon,
  PlusIcon,
  UserIcon,
} from "@heroicons/react/16/solid";
import { useCompanyProjects } from "../Context/CompanyProjectsContext";
import ProfileTeam from "./ProfileTeam";
import authService from '../services/authService'; // Import authService
import { useState } from 'react';
import WhiteLabel from "./WhiteLabel";

function Profile() {
  const {
    firstNameShort,company
  } = useCompanyProjects();

  console.log("...............................11", company?.clientPortal );

  const currentUser = authService.getCurrentUser(); // Get current user
  const location = useLocation(); // Get current location
  const navigate = useNavigate();
  const [activeState, setActiveState] = useState("Team")

  // Define navItems based on the currentUser's role
  const navItems = [
    ...(currentUser?.isCompanyOwner ? [{ label: "Team", url: "/profil" }] : []),
    ...(currentUser?.isCompanyOwner && company?.clientPortal ? [{ label: "Whitelabel", url: "/advanced" }] : []),
  ];

  function TeamDropdownMenu() {
    return (
      <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
        <DropdownItem href="/teams/1/settings">
          <Cog8ToothIcon />
          <DropdownLabel>Settings</DropdownLabel>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem href="/teams/1">
          <Avatar slot="icon" src="/tailwind-logo.svg" />
          <DropdownLabel>Tailwind Labs</DropdownLabel>
        </DropdownItem>
        <DropdownItem href="/teams/2">
          <Avatar slot="icon" initials="WC" className="bg-purple-500 text-white" />
          <DropdownLabel>Workcation</DropdownLabel>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem href="/teams/create">
          <PlusIcon />
          <DropdownLabel>New team&hellip;</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    );
  }



  return (
    <StackedLayout
      navbar={
        <Navbar>
          <Dropdown>
            <DropdownButton
              as={NavbarItem}
              className="max-lg:hidden"
              onClick={() => navigate("/")}
            >
              <Avatar src={require("../images/logo.png")} />
              <NavbarLabel>Superdash</NavbarLabel>
            </DropdownButton>
          </Dropdown>
          <NavbarDivider className="max-lg:hidden" />
          <NavbarSection className="max-lg:hidden">
            {navItems.map(({ label, url }) => (
              <NavbarItem
                key={label}
                onClick={() => {
                  setActiveState(label); // Set active state on click
                }}
                current={activeState === label} 
              >
                {label}
              </NavbarItem>
            ))}
          </NavbarSection>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar
                  initials={firstNameShort}
                  className="size-10 bg-zinc-900 text-white dark:bg-white dark:text-black"
                />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem href="/">
                  <UserIcon />
                  <DropdownLabel>Home</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <Dropdown>
              <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                <Avatar src={require("../images/logo.png")} />
                <SidebarLabel>Superdash</SidebarLabel>
                <ChevronDownIcon />
              </DropdownButton>
              <TeamDropdownMenu />
            </Dropdown>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              {navItems.map(({ label, url }) => (
                <SidebarItem
                  key={label}
                  current={activeState === label} 
                >
                  {label}
                </SidebarItem>
              ))}
            </SidebarSection>
          </SidebarBody>
        </Sidebar>
      }
    >
      {currentUser?.isCompanyOwner && activeState === "Team" && <ProfileTeam />}
      {currentUser?.isCompanyOwner && company?.clientPortal && activeState === "Whitelabel" && <WhiteLabel />}
    </StackedLayout>
  );
}

export default Profile;
