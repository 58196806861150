import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useHistory } from "react-router";
// import { Redirect } from "react-router-dom";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "../components/Form";
import * as userService from "../services/userService";
import * as metaService from "../services/metaDataService";
import * as profileService from "../services/profileService";
import * as companyService from "../services/companyService";
import auth from "../services/authService";
import { Link, Navigate } from "react-router-dom";

function Welcome(props) {
  const navigate = useNavigate();

  const [inputField] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    country: "",
  });

  const [companyId, setCompanyId] = useState("");

  const [loading, setLoading] = useState(false);

  const [countries, setCountries] = useState([]);

  const [errorMessage] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await metaService.stripeCountries();
      setCountries(data.countries);
    };
    fetchData();
  }, []);

  const template = [
    {
      name: "firstName",
      label: "Enter your first name",
      type: "text",
    },
    {
      name: "lastName",
      label: "Enter your last name",
      type: "text",
    },
    {
      name: "companyName",
      label: "Enter your company Name",
      type: "text",
    },
    {
      name: "country",
      label: "Select your country",
      type: "select",
      options: countries,
    },
  ];

  const buttonInfo = { label: "Create account", style: "entire-width" };
  // const buttonInfo1 = { label: " Create A", style: "" };

  const schema = {
    firstName: Joi.string().min(2).max(50).required().label("First  Name"),
    lastName: Joi.string().min(2).max(50).required().label("Last Name"),
    companyName: Joi.string().min(3).max(100).required().label("Company Name"),
    country: Joi.string().max(50).required().label("Country"),
  };

  const handleSubmit = async (profile) => {
    try {
      const { data } = await companyService.createCompany(profile);
      message.success("Please wait. Setting up your account.");

      const response = await profileService.connectionSetup(data._id, profile);

      if (response.status === 200) {
        window.location.replace("/");
        //     history.replace({
        //       // pathname: "/billing/" + "?flow=signup",
        //       pathname: "/" ,
        //       state: {
        //         company: data,
        //       },
        //     });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error(error.response.data.msg);
      } else {
        message.error("Something went wrong. Please try again later.");
      }
      setLoading(false);
    }
  };

  return (
    <div className="bg-slate-50  flex items-center justify-center h-screen w-screen ">
      <div className="relative w-[500px] rounded-xl bg-white shadow-[0px_0px_0px_1px_rgba(9,9,11,0.07),0px_2px_2px_0px_rgba(9,9,11,0.05)] dark:bg-zinc-900 dark:shadow-[0px_0px_0px_1px_rgba(255,255,255,0.1)] p-20">
        <div className="space-y-8">
          <Form
            formTitle={"Setup an account"}
            errorMessage={errorMessage}
            inputField={inputField}
            template={template}
            schema={schema}
            buttonInfo={buttonInfo}
            onSubmit={handleSubmit}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default Welcome;
