import http from "./httpService";
// import { apiUrl } from "../config.json";

// const apiEndpoint = apiUrl + "/companies";
const apiEndpoint = "/project";

export function createProject(obj,companyId, type ) {

  return http.post(apiEndpoint, {
    name: obj.title,
    companyId: companyId, 
    type:type,
    projectExtractionLimit: obj.projectExtractionLimit
  });
}

export function updatePrompt(projectId,key ) {

  return http.put(apiEndpoint + "/" + projectId, {
    key: key, 
  });
}

 export function getAllProjects(companyId) {
  return http.get(apiEndpoint + "/company/"+ companyId);
}

export function getSettings(projectId) {
  return http.get(apiEndpoint + "/settings/"+ projectId);
}

export function getPosts(projectId) {
  return http.get(apiEndpoint + "/settings/workflowquestions/"+ projectId);
}

export function updateProject(projectId, req) {
  return http.put(apiEndpoint + "/" + projectId, req, {
    // headers: {
    //   'Content-Type': 'application/json'
    // }
  });
}

export function updateTeamAccess(companyId, projectId, req) {
  return http.put(apiEndpoint + "/" + "teamaccess" + "/" + companyId + "/" + projectId, req);
}


export function createExtraction(projectId, req) {
  return http.post(apiEndpoint + "/" + "createextraction"+ "/" + projectId, req);
}

export function getExtraction(projectId) {
  return http.get(apiEndpoint + "/settings" + "/extractionparameters"+ "/" + projectId);
}

