import { Fragment, useState, useEffect, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  PhoneIcon,
  ListBulletIcon
} from "@heroicons/react/24/outline";
import Sidebar from "../common/sidebar";
import NavigationBar from "../common/navigationBar";
import HomePrompt from "./HomePrompt";
import HomeThread from "./HomeThread";
import HomeKnowledgebase from "./HomeKnowledgebase";
import * as profileService from "../services/profileService";
import * as projectService from "../services/projectService";
import HomeDashboard from "./HomeDashboard";
import HomeAPISettings from "./HomeAPISettings";
import HomeModules from "./HomeModules";
import HomeIntegrations from "./HomeIntegrations";
import { WebcallContext } from "../Context/WebcallContext";
import HomeCampaigns from "./HomeCampaigns";
import { useNavigate } from 'react-router-dom';
import { CompanyProjectsProvider, useCompanyProjects } from '../Context/CompanyProjectsContext';

const initialNavigation = [
  { name: "Dashboard", href: "#", icon: HomeIcon, current: true },
  { name: "Knowledge base", href: "#", icon: FolderIcon, current: false },
  { name: "Threads", href: "#", icon: UsersIcon, current: false },
  { name: "Prompt", href: "#", icon: DocumentDuplicateIcon, current: false },
  { name: "Modules", href: "#", icon: ChartPieIcon, current: false },
  { name: "Campaigns", href: "#", icon: ListBulletIcon, current: false },
  { name: "Integrations", href: "#", icon: Cog6ToothIcon, current: false },
  { name: "API Settings", href: "#", icon: Cog6ToothIcon, current: false },
];

export default function Home({ user }) {
 const [isOpen, setIsOpen] = useState(false);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigation, setNavigation] = useState(initialNavigation);
  const [sideBarSelection, setSideBarSelection] = useState("Dashboard");
  const [companyId, setCompanyId] = useState("");

  const [activeProject, setActiveProject] = useState(undefined);
  const [portalActiveProject, setPortalActiveProject] = useState(0);
  const [projectId, setProjectId] = useState([]);
  // const [KB, setKB] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [sign, setSign] = useState("");

  const [reload, setReload] = useState(false);
  const [settings, setSettings] = useState({});
  const [navigateTo, setNavigateTo] = useState(null);
  const [navigationState, setNavigationState] = useState({});
  const { cleanupResources } = useContext(WebcallContext);

  useEffect(() => {
    return () => {
      cleanupResources();
    };
  }, [sideBarSelection]);

  // Load the last known value of activeProject from localStorage on component mount
  useEffect(() => {
    const savedActiveProject = localStorage.getItem("activeProject");
    if (savedActiveProject) {
      setActiveProject(parseInt(savedActiveProject, 10));
    } else {
      setActiveProject(0); // Default to 0 if nothing is in localStorage
    }
  }, []);

  //Save the current value of activeProject to localStorage whenever it changes
  useEffect(() => {
    if (activeProject !== undefined) {
      localStorage.setItem("activeProject", activeProject.toString());
    }
  }, [activeProject]);

  const handleMenuItemClick = (itemName) => {
    const newNavigation = navigation.map((item) => ({
      ...item,
      current: item.name === itemName,
    }));
    setNavigation(newNavigation);
    setSideBarSelection(itemName);
  };

  const navigate = useNavigate();


  const { company, projects, loading, error, fetchData, refresh, setRefresh } = useCompanyProjects();



  useEffect(() => {
      if (!loading) {
          if (error) {
              if (error.response && error.response.status === 401) {
                  setNavigateTo("/logout");
              } 
              else if (error.response && error.response.status === 400 && !company) {
                  setNavigateTo("/welcome");
              }
          } else if (company) {
              if (company.accountType === "unsubscribed") {
                  setNavigateTo("/billing/");
                  setNavigationState({ company: company });
              } else if (projects.length < 1) {
                  setIsOpen(true);
              }
          }
      }
  }, [loading, error, company, projects]);


  useEffect(() => {
    if (projects.length > 1 && activeProject)
      setProjectId(projects[activeProject]._id);
  }, [projects, activeProject]);



  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (projects[activeProject]._id) {
  //         const response = await projectService.getSettings(
  //           projects[activeProject]._id
  //         );

  //         setSettings(response.data);

  //       }
  //     } catch (error) {
  //       // message.error("Failed to fetch data");
  //     }
  //   };
  //   fetchData();
  // }, [projects, reload, activeProject]);



  return (
    <>
      {navigateTo && (
        <Navigate to={navigateTo} state={navigationState} replace />
      )}
      <div>
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          navigation={navigation}
          onMenuItemClick={handleMenuItemClick}
        />
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            navigation={navigation}
            onMenuItemClick={handleMenuItemClick}
          />
        </div>
        <div className="lg:pl-72">
          <NavigationBar
            isOpen={isOpen}  
            setIsOpen={setIsOpen}          
            setSidebarOpen={setSidebarOpen}
            activeProject={activeProject}
            projects={projects}
            company={company}
            setActiveProject={setActiveProject}
            // corpus={corpus}
            sign={sign}
            setRefresh={setRefresh}
            refresh={refresh}
            user={user}
          />
          {sideBarSelection === "Dashboard" && (
            <HomeDashboard
              projects={projects}
              activeProject={activeProject}
              company={company}
            />
          )}
          {sideBarSelection === "Knowledge base" && (
            <HomeKnowledgebase
              projects={projects}
              activeProject={activeProject}
              projectId={projectId}
            />
          )}
          {sideBarSelection === "Prompt" && (
            <HomePrompt
              projects={projects}
              activeProject={activeProject}
              reload={reload}
              setReload={setReload}
              sideBarSelection={sideBarSelection}
            />
          )}
          {sideBarSelection === "Threads" && (
            <HomeThread projects={projects} activeProject={activeProject} />
          )}
 
          {sideBarSelection === "Campaigns" && (
            <HomeCampaigns projects={projects} activeProject={activeProject} />
          )} 

          {sideBarSelection === "Modules" && (
            <HomeModules
              projects={projects}
              activeProject={activeProject}
              setReload={setReload}
              reload={reload}
            />
          )}

          {sideBarSelection === "Integrations" && (
            <HomeIntegrations
              projects={projects}
              activeProject={activeProject}
            />
          )}

          {sideBarSelection === "API Settings" && (
            <HomeAPISettings
              projects={projects}
              activeProject={activeProject}
            />
          )}
        </div>
      </div>
    </>
  );
}
