import React, { useEffect, useState } from "react";
import { Subheading } from "../common/heading";
import { Button } from "../common/button";
import { Text } from "../common/text";
import * as paymentService from "../services/PaymentService";
import * as connectPlanService from "../services/customerPlans";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../common/alert";
import { message } from "antd";
import Joi from "joi-browser";
import Form from "../components/Form";

import {
  CompanyProjectsProvider,
  useCompanyProjects,
} from "../Context/CompanyProjectsContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../common/table";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../common/dropdown";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";

function WhiteLabel(props) {
  const { projects, company } = useCompanyProjects();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [packages, setPackages] = useState({});
  const [reload, setReload] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [index, setIndex] = useState("");
  const [planId, setPlanId] = useState("");
  const [isEditOpen, setIsEditOpen] = useState(false);

  const [inputField, setInputField] = useState({
    planName: "",
    oneTimeProductId: "",
    features: [],
  });

  const [errorMessage] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await connectPlanService.allPlans(company._id);
      setPackages(data.plans);
      console.log("data", data.plans);
    };
    fetchData();
  }, [reload]);

  const handleStripeConnect = async () => {
    message.info("Connecting to Stripe...");
    setLoading(true);
    try {
      const response = await paymentService.startOnboarding(company._id);
      if (response.status === 200) {
        window.location.href = response.data.url;
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status === 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        setLoading(false);
        return;
      }
    }
  };

  const template = [
    {
      name: "planName",
      description: "Example :100$ Plan",
      label: "Plan Name",
      type: "text",
    },
    {
      name: "oneTimeProductId",
      label: "ProductId for one-time top-up",
      description: "Copy paste directly from Stripe account",
      type: "text",
    },
    {
      name: "features",
      label: "Package offers",
      type: "inputFieldList",
    },
  ];

  const buttonInfo = {
    label: isEditOpen?"Edit Package":"Add Package",
    style: "entire-width",
    class:
      "w-full inline-flex items-center justify-center rounded-lg border text-base font-semibold px-4 py-2 text-white bg-zinc-900 focus:outline-none",
  };

  const schema = {
    planName: Joi.string().required().label("Plan Name"),
    oneTimeProductId: Joi.string()
      .required()
      .label("ProductID for one-time top-up"),
    features: Joi.array().items(Joi.string()).required().label("Features"),
  };

  const handlePackage = async () => {
    message.info("Adding Package...");
    setLoading(true);
  };

  const handleSubmit = async (plan) => {
    try {
      const response = await connectPlanService.addCustomerPlan(
        company._id,
        plan
      );
      if (response.status === 200) {
        message.success("Package added successfully");
        setLoading(false);
        setReload(!reload);
        setIsOpen(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status === 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        setLoading(false);
        return;
      }
    }
  };

  const showDeleteModal = (item) => {
    setPlanId(item._id);
    setIsDeleteOpen(true);
  };

  const handleEdit = (item) => {
    setInputField({
      planName: item.planName,
      oneTimeProductId: item.oneTimeProductId,
      features: item.features,
    });

    setIsEditOpen(true);
    setPlanId(item._id)
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await connectPlanService.deletePlan(
        company._id,
        planId
      );
      if (response.status === 200) {
        message.success("Package deleted successfully");
        setLoading(false);
        setReload(!reload);
        setIsDeleteOpen(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status === 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        setLoading(false);
        return;
      }
    }
  };

  const handleEditSubmit = async (data) => {
    try {
      const response = await connectPlanService.updatePlan(
        company._id,planId,
        { updatedPlan: data } 
      );
      if (response.status === 200) {
        message.success("Package updated successfully");
        setLoading(false);
        setReload(!reload);
        setIsEditOpen(false);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status === 400 &&
        ex.response.status < 500
      ) {
        message.error(ex.response.data);
        setLoading(false);
        setIsEditOpen(false);
        return;
      }
    }
  };

  return (
    <div>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Subheading>Connect Stripe </Subheading>
        <Text>
          Only applicable for organizations that have deployed the private label
          version.
        </Text>
        <div className="flex gap-4">
          <Button
            loading={loading}
            onClick={handleStripeConnect}
            disabled={loading}
          >
            Connect
          </Button>
        </div>
      </div>

      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10 mt-5">
        <Subheading>Packages offered</Subheading>
        <Text>
          Create customized voice credits top-up plans for your customers here.
        </Text>
        <div className="flex gap-4">
          <Button
            loading={loading}
            onClick={() => setIsOpen(true)}
            disabled={loading}
          >
            Add Package
          </Button>
        </div>
      </div>

      {
        <div className="border border-gray-200 rounded-lg overflow-hidden mt-10">
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
            <TableHead>
              <TableRow>
                <TableHeader>Plan Name</TableHeader>
                <TableHeader>Product ID</TableHeader>
                <TableHeader>Action</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {packages && packages.length > 0 ? (
                packages.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="font-medium">
                      {item.planName}
                    </TableCell>
                    <TableCell>{item.oneTimeProductId}</TableCell>
                    <TableCell>
                      <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                        <Dropdown>
                          <DropdownButton plain aria-label="More options">
                            <EllipsisHorizontalIcon />
                          </DropdownButton>
                          <DropdownMenu anchor="bottom end">
                            <DropdownItem
                              onClick={() => showDeleteModal(item, index)}
                            >
                              Delete
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => handleEdit(item, index)}
                            >
                              Edit
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} className="text-center">
                    No packages available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      }

      <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
        <AlertTitle>Add a custom package</AlertTitle>

        <AlertBody style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Form
              key={JSON.stringify(inputField)}
              errorMessage={errorMessage}
              inputField={inputField}
              template={template}
              schema={schema}
              buttonInfo={buttonInfo}
              onSubmit={handleSubmit}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </AlertBody>
      </Alert>

      <Alert open={isEditOpen} onClose={() => setIsEditOpen(false)} size="sm">
        <AlertTitle>Edit your package</AlertTitle>

        <AlertBody style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Form
              key={JSON.stringify(inputField)}
              errorMessage={errorMessage}
              inputField={inputField}
              template={template}
              schema={schema}
              buttonInfo={buttonInfo}
              onSubmit={handleEditSubmit}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </AlertBody>
      </Alert>

      <Alert
        open={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        size="sm"
      >
        <AlertTitle>Delete Field</AlertTitle>
        <AlertDescription>
          Once deleted, this package will no longer be accessible to your
          customers.
        </AlertDescription>
        <AlertBody style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          ></div>
        </AlertBody>
        <AlertActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button disabled={loading} onClick={() => handleDeleteConfirmation()}>
            OK
          </Button>
        </AlertActions>
      </Alert>
    </div>
  );
}

export default WhiteLabel;
