import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from '../src/common/protectedRoute';
import { ToastContainer } from 'react-toastify';
import Home from './HomePages/Home';
import "./index.css";
import Login from './SignupnLogin/Login';
import SignUp from './SignupnLogin/Signup';
import Logout from './SignupnLogin/Logout';
import Welcome from './OnboardingPages/Welcome';
import ThirdPartAuthSuccess from './HomePages/HomeIntegrations/ThirdPartAuthSuccess';
import PaymentSuccess from './PaymentPages.js/PaymentSuccess';
import Profile from './NavBarComponents/Profile';
import { CompanyProjectsProvider } from './Context/CompanyProjectsContext';
import TeamInvite from './PublicPages/TeamInvite';
import ResetPassword from './SignupnLogin/ResetPassword';
import ForgotPassword from './SignupnLogin/ForgotPassword';




// Define ProtectedRouteWithContext component
const ProtectedRouteWithContext = ({ children }) => (
  <CompanyProjectsProvider>
    <ProtectedRoute>
      {children}
    </ProtectedRoute>
  </CompanyProjectsProvider>
);

function App() {
  return (
    <div>
      <Routes>
        <Route path="/trysignup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/team-invite/:token?" element={<TeamInvite />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/logout" element={<ProtectedRouteWithContext><Logout /></ProtectedRouteWithContext>} />
        <Route path="/profile" element={<ProtectedRouteWithContext><Profile /></ProtectedRouteWithContext>} />
        <Route path="/welcome" element={<ProtectedRouteWithContext><Welcome /></ProtectedRouteWithContext>} />
        <Route path="/onboarding/:companyId" element={<ProtectedRoute><Welcome /></ProtectedRoute>} />
        <Route path="/oauth/callback" element={<ProtectedRouteWithContext><ThirdPartAuthSuccess /></ProtectedRouteWithContext>} />
        <Route path="/success/:sessionId?" element={<ProtectedRouteWithContext><PaymentSuccess /></ProtectedRouteWithContext>} />
        <Route path="/" element={<ProtectedRouteWithContext><Home /></ProtectedRouteWithContext>} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />
      </Routes>
    </div>
  );
}

export default App;