import React, { useState,useEffect } from "react";
import {
  CompanyProjectsProvider,
  useCompanyProjects,
} from "../Context/CompanyProjectsContext";

import { Heading } from "../common/heading";
import { Button } from "../common/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../common/dialog";
import Joi from "joi-browser";
import Form from "../components/Form";
import * as projectService from "../services/projectService";
import * as userService from "../services/userService";
import * as profileService from "../services/profileService";
import { message } from "antd";
import { Divider } from "../common/divider";
import { useLocation } from "react-router-dom";
import { Avatar } from "../common/avatar";
import { Badge } from "../common/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../common/table";
import { Switch } from "../common/switch";
import { getInitials } from '../utils/nameHelper';





function ProfileTeam({ companyId }) {
  const [isOpen, setIsOpen] = useState(false);
  const [formState, setFormState] = useState("add");
  const [email, setEmail] = useState("");
  const [users,setUser] = useState([]);



  const { projects, company } = useCompanyProjects();

  const [inputField] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const [errorMessage] = useState({});

  // let auth = useAuth();
  //   const history = useHistory();

  useEffect(() => {
    const fetchProfiles = async () => {
      const response = await profileService.profilesByCompany();
      setUser(response.data.profiles);
    };
    fetchProfiles();
  }, []);


  const template = [
    {
      name: "firstName",
      label: "First Name",
      type: "text",
    },
    {
      name: "lastName",
      label: "Last Name",
      type: "text",
    },
    {
      name: "email",
      label: "Email",
      type: "email",
    },
    {
      name: "password",
      label: "Password",
      type: "password",
    },
  ];

  const schema = {
    firstName: Joi.string().max(125).required().label("email"),
    lastName: Joi.string().max(125).required().label("email"),
    email: Joi.string().email().required().label("email"),
    password: Joi.string().min(8).required().label("password"),
  };

  const handleSubmit = async (user) => {
    try {
      const response = await userService.accountForTeammate(company._id, user);
      if (response.status === 200) {
        console.log("success");
        setEmail(user.email);
        setFormState("attach");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setLoading(false);
        message.error(ex.response.data.msg);
      }
    }

  };



  // const users = [
  //   {
  //     id: "1",
  //     name: "Akhil Thomas ",
  //     email: "123@gmail.com",
  //     access: "123@gmail.com",
  //     online: true,
  //   },
  //   { id: "2", name: "Akhil Thomas", access: "123@gmail.com" },
  //   { id: "3", name: "Akhil Thomas", access: "123@gmail.com" },
  //   { id: "4", name: "Akhil Thomas", access: "123@gmail.com" },
  //   { id: "5", name: "Akhil Thomas", access: "123@gmail.com" },
  // ];
  
  const handleSwitchChange = async (projectId, isActive) => {
    const emailArray = [email];

    try {
      const response = await projectService.updateTeamAccess(company._id, projectId, { 
        emails: emailArray,
        active: isActive
      });
      if (response.status === 200) {
        console.log("User access updated");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setLoading(false);
        message.error(ex.response.data.msg);
      }
    }
  };



  const handleClose = () => {
    setIsOpen(false);
    setFormState("add");
  };

  const handleAssignProjects = async (email) => {
    setFormState("attach");
    setIsOpen(true);
  }

  return (
    <div>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading>Team</Heading>
        <div className="flex gap-4">
          {/* <Button outline>Refund</Button> */}
          <Button onClick={() => setIsOpen(true)}>Invite team member</Button>
        </div>
        <Dialog open={isOpen} onClose={setIsOpen}>
          {formState === "add" && (
            <div>
              <DialogTitle className="mx-10 my-2">
                Create an account for your teammate
                <Divider className="my-2" />
              </DialogTitle>
              <DialogBody>
                <div className="m-10">
                  <Form
                    errorMessage={errorMessage}
                    inputField={inputField}
                    template={template}
                    schema={schema}
                    onSubmit={handleSubmit}
                    loading={loading}
                    setLoading={setLoading}
                    buttonInfo={{
                      style: "",
                      buttons: "2",
                      primaryLabel: "Next Step",
                      secondaryLabel: "Close",
                    }}
                    handleSecondary={handleClose}
                  />
                </div>
              </DialogBody>
            </div>
          )}

          {formState === "attach" && (
            <div>
 
                <DialogTitle>Provision access to workspaces</DialogTitle>
                <DialogDescription>
                  You can give the newly added user access to specific
                  workspaces here.
                </DialogDescription>
                <DialogBody>
                  <Table bleed compact>
                    <TableHead>
                      <TableRow>
                        <TableHeader>Name</TableHeader>
                        <TableHeader className="text-right">Access</TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projects.map((project) => (
                        <TableRow key={project._id}>
                          <TableCell className="font-medium w-1/2">
                            {project.name}
                          </TableCell>
                          <TableCell className="text-zinc-500 text-right w-1/2">
                            <Switch
                              onChange={(checked) => handleSwitchChange(project._id, checked)}
                              aria-label={`Allow access to ${project.name}`}
                              name={`allow_access_${project._id}`}
                              id={`project-switch-${project._id}`}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </DialogBody>
                <DialogActions>
                  <Button onClick={() => handleClose()}>Close</Button>
                </DialogActions>
    
            </div>
          )}
        </Dialog>
      </div>
      <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            {/* <TableHeader>Grant access</TableHeader> */}
            <TableHeader>Role</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
           {users.map((user) => (
            <TableRow key={user.handle}>
              <TableCell>
      
              <div className="flex items-center gap-4">
                <Avatar className="size-10 bg-zinc-900 text-white dark:bg-white dark:text-black" initials={getInitials(user.firstName.split(' ')[0], user.lastName.split(' ')[0])} />
                <div>
                  <div className="font-medium text-zinc-900 dark:text-white">{user.firstName}</div>
                  <div className="text-zinc-500">
                    <a href="#" className="hover:text-zinc-700">
                      {user.user?user.user.email:""}
                    </a>
                  </div>
                </div>
              </div>
       
              </TableCell>
              {/* <TableCell className="text-zinc-500"><Button onClick={() => handleAssignProjects(user.email)} outline>Assign projects</Button></TableCell> */}
              <TableCell>
                {user.isCompanyOwner ? (
                  <Badge color="lime">Owner</Badge>
                ) : (
                  <Badge color="zinc">User</Badge>
                )}
              </TableCell>
            </TableRow>
          ))} 
        </TableBody>
      </Table>
    </div>
  );
}

export default ProfileTeam;
