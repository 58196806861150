import http from "./httpService";
const apiEndpoint =  "/profile";

export function connectionSetup(companyId, data) {
    return http.post(apiEndpoint , {
        companyId: companyId,
        firstName : data.firstName,
        lastName : data.lastName
    });
}


export function allUserDetails() {
    return http.get(apiEndpoint + "/me");
  }


  export function profilesByCompany() {
    return http.get(apiEndpoint + "/profilesByCompany");
  }
