import React, { useEffect, useState } from "react";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import CopyToClipboard from "react-copy-to-clipboard";
import CopyInput from "../common/CopyInput";
import { message } from "antd";
import {
  Alert,
  AlertActions,
  AlertBody,
  AlertDescription,
  AlertTitle,
} from "../common/alert";
import { Button } from "../common/button";
import { Input } from "../common/input";
import * as keyService from "../../src/services/keyService";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../common/table";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../common/dropdown";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";

function HomeAPISettings({ projects, activeProject }) {
  const [copyProjectId, setCopyProjectId] = useState("");
  let [isOpen, setIsOpen] = useState(false);
  const[twilioURL, setTwilioURL] = useState("")

  const[smsURL, setSmsURL] = useState("")

  const [key, setKey] = useState("");
  const [keyData, setKeys] = useState([]);
  const [reload, setReload] = useState(false);

  const handleCopyButton = () => {
    message.success("Value copied");
  };

  useEffect(() => {
    const fetchData = async () => {
      if (projects && activeProject !== undefined && projects[activeProject]) {
        const { data } = await keyService.allKeys(projects[activeProject]._id);
        setKeys(data);
      }
    };

    fetchData();
  }, [isOpen, copyProjectId, reload]);



  useEffect(() => {
    if (projects && activeProject !== undefined && projects[activeProject]) {
      setCopyProjectId(`${projects[activeProject]._id}`);
      const TwilioURLCopy = `${process.env.REACT_APP_VOICE_API}/superdash-voice-webhook/${projects[activeProject]._id}`;
      setTwilioURL(TwilioURLCopy); // This line will create a new string reference
      const SMSURLCopy = `${process.env.REACT_APP_API_POINT}api/twilio/respondToSMS/${projects[activeProject]._id}`;
      setSmsURL(SMSURLCopy)
    }
  }, [projects, activeProject]);

  const handleCreateKey = async () => {
    message.info("please wait");
    try {
      const response = await keyService.generateKey(
        projects[activeProject]._id
      );
      if (response.status === 200) {
        setKey(response.data.apiKey.key);
        setIsOpen(true);
      }
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status < 500
      ) {
        message.error("Please upgrade your account");
        setIsOpen(false);
        return;
      }
      message.error("Something is wrong");
      setIsOpen(false);
      return;
    }
  };

  const getTime = (data) => {
    if (data === 0) return "Never";

    const epochTime = data;
    const date = new Date(epochTime);
    const dateString = date.toLocaleString(); // or use toUTCString() or toDateString() as needed
    return dateString;
  };

  const handleDeleteKey = async (etag) => {
    setKey("");
    try {
      const { data } = await keyService.deleteKey(projects[activeProject]._id, etag);
      message.success("Key deleted succesfully");
      setReload(!reload);
    } catch (error) {
      message.error("Failed to delete key");
      // Optionally, handle the error by updating the UI or notifying the user
      // For example, you might want to display an error message or reopen the delete modal
      // setDeleteModalOpen(true);
    }
  };



  return (
    <div>
      <main className="lg:pl-22 bg-slate-50 h-screen">
        <div className="xl:pr-96 bg-slate-50">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6 bg-slate-50">
            <div className="divide-y divide-gray-200 overflow-visible rounded-lg bg-white shadow my-10">
              <div className="flex justify-between items-center px-4 py-5 sm:px-6">
                <div>API keys</div>
                <Button type="button" onClick={handleCreateKey}>
                  Create Key
                </Button>
              </div>
              <Alert open={isOpen} onClose={() => setIsOpen(false)} size="sm">
                <AlertTitle>API Key Generated</AlertTitle>
                <AlertDescription>
                  For security purposes, it's advised that you save this secret
                  key in a secure and accessible location. Once saved, please
                  note that it won't be visible again through your Superdash
                  account. If the secret key is lost, generating a new one will
                  be required.
                </AlertDescription>
                <AlertBody style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <CopyInput  key="key" value={key} />
                  </div>
                </AlertBody>
                <AlertActions>
                  <Button plain onClick={() => setIsOpen(false)}>
                    Cancel
                  </Button>
                  <Button onClick={() => setIsOpen(false)}>OK</Button>
                </AlertActions>
              </Alert>
              <div className="bg-white ">
                <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] p-5">
                  {keyData &&
                    keyData.apiKeys &&
                    keyData.apiKeys.length >= 1 && (
                      <TableHead>
                        <TableRow>
                          <TableHeader>Secret Key</TableHeader>
                          <TableHeader>Created</TableHeader>
                          <TableHeader>Last Used</TableHeader>
                          <TableHeader className="relative w-0">
                            <span className="sr-only">Actions</span>
                          </TableHeader>
                        </TableRow>
                      </TableHead>
                    )}
                  <TableBody>
                    {keyData.apiKeys &&
                      keyData.apiKeys.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell className="font-medium">
                            {item.redactedKey}
                          </TableCell>
                          <TableCell>{getTime(item.createdAt)}</TableCell>
                          <TableCell className="text-zinc-500">
                            {getTime(item.lastUsed)}
                          </TableCell>
                          <TableCell>
                            <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                              <Dropdown>
                                <DropdownButton plain aria-label="More options">
                                  <EllipsisHorizontalIcon />
                                </DropdownButton>
                                <DropdownMenu anchor="bottom end">
                                  {/* <DropdownItem>View</DropdownItem>
                        <DropdownItem>Edit</DropdownItem> */}
                                  <DropdownItem
                                    onClick={() => handleDeleteKey(item._id)}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </main>

      <aside className="fixed inset-y-0 right-0 hidden w-96 overflow-y-auto border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block bg-slate-50 mt-20 mx-1 rounded-2xl shadow-md bg-white">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 p-10">
          <p>
            Here you can find other information to successfully configure your
            API connections.
          </p>
          <br />
          <CopyToClipboard text={copyProjectId}>
            <div
              onClick={handleCopyButton}
              className="flex items-center space-x-2 cursor-pointer"
            >
              {" "}
              <h3>ProjectId</h3>
              <DocumentDuplicateIcon className="w-6 h-6" />{" "}
            </div>
          </CopyToClipboard>
          <div></div>
          <p className="text-gray-400 my-1">{copyProjectId}</p>{" "}
          <div className="relative mt-10">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex items-center justify-between">
        <span className="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">Twilio Voice URL</span>
      </div>
    
    </div>
    <CopyInput key={twilioURL} value={twilioURL} />

    <div className="relative mt-10">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex items-center justify-between">
        <span className="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">Twilio SMS URL</span>
      </div>
      <CopyInput key={smsURL} value={smsURL} />
    </div>
        </div>
      </aside>
    </div>
  );
}

export default HomeAPISettings;


